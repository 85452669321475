<template>
  <div class="col">
    <div class="p-2" :class="updated ? 'updated' : 'm-card'">
      <BCard :bg="brew.styleColor">
        <BHeader>
          <template v-slot:header>
            {{ brew.formatCount }}
            {{ brew.recipeName }}
            <!-- <ProgressBarTime hight="2" :start="brew.start" :end="brew.end" /> -->
          </template>
          <template v-slot:subheader>
            {{ brew.recipeName }}
            <!-- {{ styles[brew.styleId].name }} -->
          </template>
          <template v-slot:subheaderright>
            {{ brew.recipeCopy.batchSize }} {{ brew.tankUnit }}
          </template>
        </BHeader>
        <BBody>
          <template v-slot:lable>
            tank:
          </template>
          <template v-slot:large>
            {{ brew.tankNameShort }}
          </template>
          <template v-slot:items>
            <BStockInBrewItem
              v-for="(ingredient, index) in brew.ingredients"
              :ingredient="ingredient"
              :key="index"
            />
          </template>
        </BBody>
        <hr class="line my-1" />
        <BrewState :brew="brew" />
        <BFooter>
          <template v-slot:top>
            <span> {{ brew.startText }}</span>
            <span>{{ brew.endText }}</span>
            <!-- <BTime text="start:" v-if="brew.start" :time="brew.start" /> -->
            <!-- <BTime text="end:" v-if="brew.end" :time="brew.end" /> -->
          </template>
          <template v-slot:bottom>
            <ButtonGroup>
              <ButtonLink name="BrewDetail" :params="brew.id">
                <BIconEye class="icon" />
              </ButtonLink>
              <Button @click="openBrew(brew.id)">
                <BIconPencilSquare class="icon" />
              </Button>
            </ButtonGroup>
          </template>
        </BFooter>
      </BCard>
      <div class="todo">
        <hr class="line" />
        <div v-for="(item, index) in ['TODO']" :key="index">
          <!-- <div v-for="(item, index) in getTodo(brew)" :key="index"> -->
          <div class="p-2 small">
            {{ item.message }}
          </div>
          <hr class="line" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import comp from "../../components/base"
import icons from "../../assets/icons"
// import Banner from "./Banner.vue"

// import { addDays } from "date-fns"

import { mapActions } from "vuex"

import { BREW } from "../../services/enums"

import getTodo from "../../services/brewTodo"
import { defineComponent, PropType } from "@vue/runtime-core"
import { PopBrew } from "@/typings/types"
import { useStore } from "@/store/store"

export default defineComponent({
  Name: "HomeBrewCard",
  props: {
    brew: {
      type: Object as PropType<PopBrew>,
      required: true,
    },
  },
  setup() {
    return {
      getTodo,
      BREW,
    }
  },
  data() {
    return {
      updated: false,
    }
  },
  components: {
    ...comp,
    ...icons,
    // Banner,
  },
  watch: {
    brew() {
      if (useStore().state.updateTracker.updatedItemId == this.brew.id) {
        this.updated = true
        setTimeout(() => (this.updated = false), 1000)
      }
    },
  },
  methods: {
    ...mapActions(["openBrew"]),
  },
})
</script>

<style scoped>
.todo {
  background-color: rgb(235, 166, 157);
}
.card-button {
  background-color: rgba(46, 42, 41, 0.493);
}
</style>
