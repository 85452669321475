import { handleDate } from "@/helper/helper"
import { BREW, STOCK } from "@/services/enums"
import { store } from "@/store/store"
import { PopBrew } from "@/typings/types"
import { addDays } from "date-fns"

const warning = function(message: string) {
  return {
    title: "title",
    message: message,
    callback: [],
  }
}

const startDateExcited = function(brew: PopBrew) {
  if (handleDate(brew.start) < new Date() && brew.state == BREW.PLANNING)
    return warning("This brew is set to planing but the brewing start date is excited")
}

const endDateExcited = function(brew: PopBrew) {
  const brewEnd = addDays(handleDate(brew.start), brew.fermentationTime)
  if (brewEnd < new Date() && brew.state <= BREW.FERMENTING)
    return warning("According to the schedule this brew shut be done brewing")
}

// const stocksNotOrdered = function(brew: PopBrew) {
//   const stocksId = brew.stocks.map(item => item.id)
//   const stock = stocksId.map(id => store.state.stocks[id])
//   const orderStock = stock.filter(item => item.state < STOCK.ORDERED)
//   const num = orderStock.length
//   if (num > 0) return warning(`This brew contain ${num} stock that has not been ordered`)
// }

export default function(brew: PopBrew) {
  const list = []
  if (startDateExcited(brew)) list.push(startDateExcited(brew))
  if (endDateExcited(brew)) list.push(endDateExcited(brew))
  // if (stocksNotOrdered(brew)) list.push(stocksNotOrdered(brew))
  return list
}
