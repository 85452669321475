
import comp from "../../components/base"
import icons from "../../assets/icons"
// import Banner from "./Banner.vue"

// import { addDays } from "date-fns"

import { mapActions } from "vuex"

import { BREW } from "../../services/enums"

import getTodo from "../../services/brewTodo"
import { defineComponent, PropType } from "@vue/runtime-core"
import { PopBrew } from "@/typings/types"
import { useStore } from "@/store/store"

export default defineComponent({
  Name: "HomeBrewCard",
  props: {
    brew: {
      type: Object as PropType<PopBrew>,
      required: true,
    },
  },
  setup() {
    return {
      getTodo,
      BREW,
    }
  },
  data() {
    return {
      updated: false,
    }
  },
  components: {
    ...comp,
    ...icons,
    // Banner,
  },
  watch: {
    brew() {
      if (useStore().state.updateTracker.updatedItemId == this.brew.id) {
        this.updated = true
        setTimeout(() => (this.updated = false), 1000)
      }
    },
  },
  methods: {
    ...mapActions(["openBrew"]),
  },
})
