<template>
  <Container>
    <Header>
      Planning
      <template #right>
        <BadgeBtn @click="openRecipe()" class="mx-2">
          + Recipe
        </BadgeBtn>
        <BadgeBtn @click="openBrew()">
          + Brew
        </BadgeBtn>
      </template>
    </Header>

    <TimeChart />

    <CardGray
      v-model="search"
      header="BrewCards"
      placeholder="search (recipe name, style, ingrediens)"
    >
      <ColumnCardGrid>
        <HomeBrewCard v-for="(brew, index) in searched" :key="index" :brew="brew" />
      </ColumnCardGrid>
    </CardGray>
  </Container>
</template>

<script>
import Fuse from "fuse.js"
import { mapActions, mapGetters } from "vuex"
import comp from "../components/base"
import HomeBrewCard from "../components/planning/HomeBrewCard.vue"
import TimeChart from "../components/planning/TimeChart.vue"
import icons from "../assets/icons"
import CardGray from "../components/base/CardGray.vue"

export default {
  name: "Planning",
  components: {
    ...comp,
    ...icons,
    HomeBrewCard,
    TimeChart,
    CardGray,
  },
  data() {
    return {
      search: "",
    }
  },
  computed: {
    ...mapGetters(["popBrews"]),
    populatedBrews() {
      return Object.values(this.popBrews)
    },
    fuse() {
      return new Fuse(this.populatedBrews, {
        keys: ["recipeName", "styleName", "stockNames"],
      })
    },
    searched() {
      return this.search
        ? this.fuse.search(this.search).map(item => item.item)
        : this.populatedBrews
    },
  },
  methods: {
    ...mapActions(["openBrew", "openRecipe"]),
  },
}
</script>
